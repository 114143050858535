import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../shared/util/services/data/DataService";
import { getFundRequest } from "../../util/services/data/InternalDataService";
import { SpaceBetween } from "@amzn/awsui-components-react";
import withAppLayout from "../../../shared/components/withAppLayout";
import Loading from "../../../shared/components/common/Loading";
import { getBreadcrumbs } from "../../../shared/util/common/getBreadcrumbs";
import StatusBar from "../../../shared/components/StatusBar";
import { v4 as uuid } from "uuid";
import { ReviewSectionsFactory } from "./Sections/ReviewSectionsFactory";
import { filterFields, getInternalTemplate, schema } from "../../config/schema";
import { ReviewHeaderFactory } from "./Header/ReviewHeaderFactory";
import PropTypes from "prop-types";
import { PAGE_TITLE } from "../../util/constants/pages";
import { TITLE } from "../../../shared/util/constants/title";
import { ValueFactory } from "./Sections/ValueFactory";
import { convertSMPtoMAP } from "../../../shared/util/common/convertSMPtoMAP";
import { PROGRAM_NAME_TYPE } from "../../../shared/util/constants/programType";
import { setErrorMessage } from "../../../shared/util/common/helper";
import { FundingHistorySection } from "./Sections/FundingHistory/FundingHistorySection";
import ActionItems from "./ActionItems";
import { PartnerApplicationSection } from "./Sections/PartnerApplication/PartnerApplicationSection";
import useHelpText from "../../../shared/util/hooks/useHelpText";
import FundRequestsDuplicatesSection from "./Sections/DuplicateFundRequests";
import { MAP2024_MAP_PREFIX } from "../../../shared/util/constants/fund-request";

const Review = () => {
  const history = useHistory();
  const { page, id } = useParams();
  const [isLoading, setIsLoading] = useState({
    review: true,
    reject: false,
    approve: false,
    completed: false,
  });
  const [fundingData, setFundingData] = useState();
  const [notificationsItems, setNotificationsItems] = useState([]);
  const [status, setStatus] = useState();
  const [
    selectedMigrationEngagementItems,
    setSelectedMigrationEngagementItems,
  ] = useState([]);
  const [splitPanelData, setSplitPanelData] = useState(null);
  const [splitPanelEnabled, setSplitPanelEnabled] = useState(false);
  const [stage, setStage] = useState();
  const {
    isHelperPanelOpen,
    helperPanelContent,
    setIsHelperPanelOpen,
    showHelperPanel,
  } = useHelpText();

  useEffect(async () => {
    document.title = DOMPurify.sanitize(
      `${TITLE} - ${id} - ${PAGE_TITLE[page]}`
    );

    if (id.startsWith(MAP2024_MAP_PREFIX)) {
      history.push(`/fund-requests/${id}/details`);
    }

    await loadFundRequestInformation();
    if (!splitPanelData) {
      setSplitPanelEnabled(true);
    }
  }, []);

  const loadFundRequestInformation = async () => {
    setIsLoading({ ...isLoading, review: true });
    try {
      const response = await retryApiCall({ callApi: getFundRequest(`${id}`) });

      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems,
          content: response.message,
          status: response.status,
        });
        setIsLoading({ ...isLoading, review: false });
        return;
      }

      const {
        status,
        stage,
        fundingTemplate: { program, fundingType, subProgram, migrationPhase },
      } = response;

      setFundingData({
        program,
        migrationPhase,
        subProgram,
        fundingType,
        ...response,
      });
      setStatus(status);
      setStage(stage);
      setIsLoading({ ...isLoading, review: false });
    } catch (e) {
      setErrorMessage({
        setNotificationsItems,
      });
      setFundingData({});
      setIsLoading({ ...isLoading, review: false });
      console.error(e);
    }

    setIsLoading({ ...isLoading, review: false });
  };

  const ReviewSections = ({ fundingData, migrationEngagementProps }) => {
    let template;
    template = getInternalTemplate({
      program: fundingData.program,
      migrationPhase: fundingData.migrationPhase,
      fundingType: fundingData.fundingType,
    });

    return Object.keys(template).map((step, index) => {
      return (
        <React.Fragment key={uuid()}>
          {ReviewSectionsFactory(step)({
            step,
            index,
            template,
            fundingData,
            page,
            filterFields,
            setNotificationsItems,
            schema,
            valueFactory: ValueFactory,
            migrationEngagementProps: migrationEngagementProps,
          })}
        </React.Fragment>
      );
    });
  };

  const Header = ReviewHeaderFactory({ page, stage, status });

  return isLoading["review"] ? (
    <Loading />
  ) : (
    withAppLayout({
      component: fundingData ? (
        <SpaceBetween size="l">
          <Header
            history={history}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            fundRequestId={fundingData["fundRequestId"]}
            program={convertSMPtoMAP(PROGRAM_NAME_TYPE[fundingData["program"]])}
            setNotificationsItems={setNotificationsItems}
            status={status}
            stage={stage}
            page={page}
            assignee={fundingData["assignee"]}
            fundingType={fundingData["fundingType"]}
            refresh={loadFundRequestInformation}
            fundingData={fundingData}
          />
          <StatusBar
            stage={stage}
            program={fundingData["program"]}
            fundingType={fundingData["fundingType"]}
            data={fundingData}
          />
          <ReviewSections
            fundingData={fundingData}
            migrationEngagementProps={{
              setSplitPanelData: setSplitPanelData,
              selectedMigrationEngagementItems:
                selectedMigrationEngagementItems,
              setSelectedMigrationEngagementItems:
                setSelectedMigrationEngagementItems,
            }}
          />
          <PartnerApplicationSection
            setNotificationsItems={setNotificationsItems}
          />
          <FundRequestsDuplicatesSection
            setNotificationsItems={setNotificationsItems}
            stage={fundingData["stage"]}
            program={fundingData["fundingTemplate"]["program"]}
          />
          <FundingHistorySection
            setNotificationsItems={setNotificationsItems}
            showHelperPanel={showHelperPanel}
          />
        </SpaceBetween>
      ) : (
        ""
      ),
      toolsContent: <ActionItems context={{ helperPanelContent }} />,
      breadcrumbs: getBreadcrumbs({
        text: fundingData
          ? convertSMPtoMAP(PROGRAM_NAME_TYPE[fundingData.program])
          : "",
      }),
      isToolsOpen: isHelperPanelOpen,
      onToolsChange: ({ detail }) => {
        setIsHelperPanelOpen(detail.open);
      },
      notificationsItems: notificationsItems,
      splitPanelData: splitPanelData,
      splitPanelEnabled: splitPanelEnabled,
      onSplitPanelToggle: () => {
        setSplitPanelEnabled(!splitPanelEnabled);
      },
    })()
  );
};

export default Review;

Review.propTypes = {
  reviewType: PropTypes.string,
};
